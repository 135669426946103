import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import Text from '../components/Text';
import image from '../img/lage-groesse.jpg';
import locationImages from '../img/kindertagespflege-kleinmachnow.jpg';
import { FiCheck } from 'react-icons/fi';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import BackButton from '../components/BackButton';

export const LocationPageTemplate: React.FC<any> = ({ title, text, list }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });

  const List = () => (
    <div>
      {list.map((item) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 0',
          }}
        >
          <FiCheck
            style={{
              marginRight: 12,
              color: 'hsl(100, 90%, 40%)',
              fontSize: 25,
            }}
          />
          <span style={{ fontSize: 17 }}>{item.text}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div style={{ display: 'flex' }}>
      <Content>
        <PageTitle>{title}</PageTitle>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4868.487658262021!2d13.178015!3d52.402257!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a8592e3cdf3905%3A0x1b73c49f2077272a!2sLindenbahn%2026%2C%2014532%20Kleinmachnow!5e0!3m2!1sde!2sde!4v1608488101988!5m2!1sde!2sde"
          height={300}
          frameBorder={0}
          style={{ border: 'none', marginBottom: '30px' }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        />
        <Text style={{ marginBottom: '30px' }}>{text}</Text>
        {isTabletOrMobile && (
          <div style={{ marginBottom: '40px' }}>
            <List />
          </div>
        )}
        <img src={locationImages} style={{ width: '100%' }} />
        <BackButton
          to="/"
          text="Zurück zur Startseite"
          style={{ marginTop: '30px' }}
        />
      </Content>
      {!isTabletOrMobile && (
        <div>
          <img src={image} style={{ width: '100%', marginBottom: '30px' }} />
          <List />
        </div>
      )}
    </div>
  );
};

const Content = styled.div`
  flex: 1;
  margin-right: 80px;
  display: flex;
  flex-direction: column;
`;

const LocationPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <LocationPageTemplate
        title={post.frontmatter.title}
        text={post.frontmatter.text}
        list={post.frontmatter.list}
      />
    </Layout>
  );
};

export default LocationPage;

export const query = graphql`
  query LocationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
        list {
          text
        }
      }
    }
  }
`;
